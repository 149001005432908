<template>
  <div>
    <!-- 头部 -->
    <section class="section p0 panel header_video">
      <div class="col-lg-12 p0 container_max">
        <video
          autoplay
          muted
          playsInline="{true}"
          class="video_box"
          webkit-playsinline="true"
          x-webkit-airplay="true"
          x5-playsinline="true"
          x5-video-orientation="portraint"
          src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/blo/bol1.mp4"
        ></video>

        <h1
          class="T88px_Regular colorfff header_title wow animate__fadeInUp"
          :class="$t('globalHeader.home') == 'Home' ? 'header_title_max' : ''"
        >
          {{ $t("blobrand.header_title") }}
        </h1>
      </div>
    </section>

    <!-- 项目背景 -->
    <section class="section p0 project_bj_box">
      <div class="container">
        <div
          class="proj_bj_text proj_bj_p1 T24px_Regular_ya colorfff wow animate__fadeInUp"
          data-wow-delay=".2s"
        >
          {{ $t("blobrand.proj_bj_p1") }}
        </div>
        <div class="flex-cc">
          <img
            src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/blo/brand/image1.png"
            class="image_logo"
            alt=""
          />
        </div>
        <div
          class="proj_bj_text proj_bj_p1 T24px_Regular_ya colorfff wow animate__fadeInUp"
          data-wow-delay=".3s"
        >
          <span class="color1D73FF">
            {{ $t("blobrand.proj_bj_p2_title") }}
          </span>
          {{ $t("blobrand.proj_bj_p2") }}
        </div>

        <div
          class="proj_bj_text proj_bj_p1 T24px_Regular_ya colorfff wow animate__fadeInUp"
          data-wow-delay=".3s"
        >
          {{ $t("blobrand.proj_bj_p3") }}
        </div>
        <div
          class="proj_bj_text proj_bj_p1 T24px_Regular_ya colorfff wow animate__fadeInUp"
          data-wow-delay=".3s"
        >
          {{ $t("blobrand.proj_bj_p4") }}
        </div>
        <div
          class="proj_bj_text proj_bj_p1 T24px_Regular_ya colorfff wow animate__fadeInUp"
          data-wow-delay=".3s"
        >
          {{ $t("blobrand.proj_bj_p5_title")
          }}<span class="color1D73FF">
            {{ $t("blobrand.proj_bj_p6") }}
          </span>
          {{ $t("blobrand.proj_bj_p7") }}
        </div>

        <div class="flex-cc">
          <img
            src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/blo/brand/image2.png"
            class="image_logo"
            alt=""
          />
        </div>
        <div
          class="proj_bj_text proj_bj_p1 T24px_Regular_ya colorfff wow animate__fadeInUp"
          data-wow-delay=".3s"
        >
          {{ $t("blobrand.proj_bj_p8_title") }}
          <span class="color1D73FF">
            {{ $t("blobrand.proj_bj_p9") }}
          </span>
          {{ $t("blobrand.proj_bj_p10") }}
        </div>

        <div
          class="proj_bj_text proj_bj_p1 T24px_Regular_ya colorfff wow animate__fadeInUp"
          data-wow-delay=".3s"
        >
          {{ $t("blobrand.proj_bj_p11") }}
        </div>

        <div
          class="proj_bj_text proj_bj_p1 T24px_Regular_ya colorfff wow animate__fadeInUp"
          data-wow-delay=".3s"
        >
          {{ $t("blobrand.proj_bj_p12") }}

          <div
            class="proj_bj_text proj_bj_p1 T24px_Regular_ya colorfff wow animate__fadeInUp"
            data-wow-delay=".3s"
          >
            <span class="color1D73FF">
              {{ $t("blobrand.proj_bj_p13") }}
            </span>
            {{ $t("blobrand.proj_bj_p14") }}
          </div>

          <div
            class="proj_bj_text T24px_Regular_ya colorfff wow animate__fadeInUp"
            data-wow-delay=".3s"
          >
            {{ $t("blobrand.proj_bj_p15") }}
          </div>
        </div>
      </div>
    </section>

    <!-- 项目总结 -->
    <section class="section project_summarize_box">
      <div class="container">
        <div class="customized_box flex-w">
          <div class="flex-c">
            <span class="T24px_Regular colorfff">
              {{ $t("customized.title") }}
              <span class="color1D73FF">{{ $t("customized.title1") }}</span>
            </span>
            <span class="T16px_Regular color818181">
              {{ $t("customized.tipe") }}
            </span>
          </div>
          <div class="flex-c">
            <span v-show="$t('globalHeader.home') != 'Home'" class="T14px_Regular colorfff tips_net">
              {{ $t("customized.tipe_ri") }}
            </span>
            <span
              @click="routerBtn('/contact')"
              class="T20px_Regular colorfff my_btn animation_right"
            >
              {{ $t("customized.btn") }}
            </span>
          </div>
        </div>

        <div class="case_box">
          <header class="case_header T36px_Regular colorfff">
            {{ $t("blolist.gengduowenz") }}
          </header>
          <div class="case_list flex-w col-lg-12">
            <div
              class="col-12 info_item_box_col wow animate__fadeInUp"
              @click="routerBtn(item.routerUrl)"
              v-for="(item, index) in caseList"
              :key="index"
            >
              <div class="flex-w info_item_box">
                <div class="blog_image_logo_box">
                  <img class="blog_image_logo" :src="item.url" alt="" />
                </div>

                <div class="info_box flex-c">
                  <span class="T16px_Regular time">{{ item.time }}</span>

                  <span class="T32px_Regular_ya title colorfff">
                    {{ item.title }}
                  </span>

                  <footer class="T20px_Regular footer_info">
                    <span class="colorfff">{{ $t("home.gengduo") }}</span>
                    <img
                      class="jq_icon"
                      src="https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/jq.png"
                      alt=""
                    />
                  </footer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { gsap } from "gsap";
import ScrollMagic from "scrollmagic";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, gsap);
gsap.registerPlugin(ScrollTrigger);

import { WOW } from "wowjs";
export default {
  data() {
    return {
      caseList: [
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/blo/bol1_img.png",
          time: this.$t("home.blog_list1_time"),
          title: this.$t("home.blog_list1_title"),
          routerUrl: "/blobrand",
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/blo/bol2_img.png",
          time: this.$t("home.blog_list2_time"),
          title: this.$t("home.blog_list2_title"),
          routerUrl: "/blotob",
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/blo/bol3_img.png",
          time: this.$t("home.blog_list3_time"),
          title: this.$t("home.blog_list3_title"),
          routerUrl: "/bloaddtob",
        },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
    this.slideInit();
    const wow = new WOW();
    wow.init();
  },
  methods: {
    /**
     * 滑动页面效果初始化
     */
    slideInit() {
      gsap.registerPlugin(ScrollTrigger);
      let panels = gsap.utils.toArray(".panel");
      panels.map((panel) =>
        ScrollTrigger.create({ trigger: panel, start: "top top" })
      );
      panels.forEach((panel) => {
        ScrollTrigger.create({
          trigger: panel,
          start: () =>
            panel.offsetHeight < window.innerHeight
              ? "top top"
              : "bottom bottom",
          pin: true,
          pinSpacing: false,
        });
      });
    },
    /**
     * 路由跳转方法
     * @param {*} id 文章id
     * @param {*} url 跳转路由
     */

    routerBtn(url, id) {
      if (id) {
        this.$router.push({ path: url, query: { id: id } });
        return;
      }
      this.$router.push({ path: url });
    },
  },
};
</script>

<style lang="less" scoped>
.panel {
  background: #15171d;
}
.header_video {
  .container_max {
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .header_title {
      position: absolute;
      top: 3.375rem;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      z-index: 2;
    }
    .header_title_max {
      font-size: 1.9rem;
    }
  }
  .container_max::after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: rgba(22, 39, 50, 0.74);
    z-index: 1;
  }
  .container_max::before {
    content: "";
    display: inline-block;
    width: 110%;
    height: 1.8875rem;
    background: #15171d;
    filter: blur(0.3375rem);
    position: absolute;
    // padding: 0 .5313rem;
    top: 13.0875rem;
    left: -0.5313rem;
    z-index: 2;
  }

  .video_box {
    object-fit: cover;
    width: 100vw;
    height: 14.1875rem;
    background: rgba(22, 39, 50, 0.74);
  }
}

.project_bj_box {
  background: #15171d;
  padding-top: 1.25rem !important;
  .container {
    // margin: 0 13.5rem;
    // max-width: calc(100vw - 27rem);

    .proj_bj_p1 {
      margin-top: 0.9375rem;
      margin-bottom: 0.75625rem;
    }

    .proj_bj_text {
      line-height: 1.488rem;
      letter-spacing: 0.0313rem;
    }
    .image_logo {
      width: 19.3438rem;
      height: 14.5rem;
      margin: 0.9375rem 0 1.25rem 0;
    }
  }
}

.project_challenge_box {
  background: #15171d;
  padding-top: 4.6875rem;
  padding-bottom: 0;

  .container {
    margin: 0 13.5rem;
    max-width: calc(100vw - 27rem);

    .proj_bj_p1 {
      margin-top: 0.9375rem;
      margin-bottom: 0.75625rem;
    }

    .proj_bj_p2 {
      margin-bottom: 2.5rem;
    }

    .proj_bj_p3 {
      margin-bottom: 3.125rem;
    }

    .proj_bj_text {
      line-height: 1.488rem;
      letter-spacing: 0.0313rem;
    }
  }

  .carousel_box {
    background-image: url("https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/hire/project_challenge_bj.png");
    background-size: contain;
    background-position: left bottom;
    background-position-x: -0.75rem;
    background-repeat: no-repeat;
    height: 28.125rem;
    padding-top: 8.75rem;

    .carousel_item {
      // width: 100%;
      // height: 100%;
      justify-content: flex-end;
      align-content: center;

      .carousel_img {
        width: 8.4063rem;
        height: 16.4063rem;
        margin-bottom: 1.375rem;
        border-radius: 0.9375rem;
      }
    }
  }
}

.project_summarize_box {
  background: #15171d;
  padding: 1.55rem 0;

  .customized_box {
    margin-top: 1.525rem;
    min-height: 7.9375rem;
    padding: 1.2rem 0.9813rem 1.2rem 0.9188rem;
    background-image: url("https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/dingzhi.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    // display: inline-block;
    .T36px_Regular {
      font-size: 1rem;
    }
    .tips_net {
      height: 0.8438rem;
    }

    .my_btn {
      width: 5.8125rem;
      height: 1.7813rem;
      border: 0.0313rem solid #ffffff;
      text-align: center;
      line-height: 1.7813rem;
    }
  }

  .case_box {
    // margin: 0 5.875rem;
    margin-top: 1.4125rem;

    .case_list {
      margin-top: 0.9375rem;
      .info_item_box {
        width: 100%;
        margin-bottom: 2rem;
        border: 0.0313rem solid #c3c3c4;

        &:hover {
          .blog_image_logo {
            transform: scale(1.05);
            transition: all 0.8s;
          }
        }

        .blog_image_logo_box {
          width: 100%;
          height: 6.4375rem;
          margin-bottom: 0.7625rem;
          overflow: hidden;

          .blog_image_logo {
            width: 100%;
            height: 100%;
            transition: all 0.8s;
          }
        }

        .info_box {
          padding: 0 1.0938rem 1.7813rem 1.0938rem;
          width: calc(100%);

          .title {
            margin-top: 0.25rem;
            margin-bottom: 2.125rem;
            display: -webkit-box; /* 必须结合 */
            -webkit-box-orient: vertical; /* 必须结合 */
            -webkit-line-clamp: 2; /* 限制显示6行 */
            overflow: hidden; /* 超出部分隐藏 */
          }
        }

        .footer_info {
          position: relative;
          // letter-spacing: .125rem;

          .jq_icon {
            width: 0.875rem;
            height: 0.875rem;
            margin-left: 0.25rem;
          }
        }
      }
    }
  }
}

::v-deep .el-carousel__mask {
  background: transparent;
}
</style>
